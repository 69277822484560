import { APIFilter } from '@/utils/api'

export default {
  async selectDato (Vue, filter, search, sorter, page, idtabla, id) {
    const apiFilter = new APIFilter()
      .addExact('idtabla', idtabla)
      .addExact('id', id)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.dato.searchFilter(search))
    }
    if (filter.tdatoDescripcion.value) {
      apiFilter.addILike(filter.tdatoDescripcion.field, filter.tdatoDescripcion.value)
    }
    if (filter.valorCalculado.value) {
      apiFilter.addILike(filter.valorCalculado.field, filter.valorCalculado.value)
    }
    const resp = await Vue.$api.call('dato.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectDatoRows (Vue, pks) {
    const apiFilter = new APIFilter()
      .addIn('iddato', pks)
    const resp = await Vue.$api.call('dato.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
