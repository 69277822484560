<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron datos personalizados"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="iddato"
    >
      <template
        #listItem="slotProps"
      >
        <dato-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <b10-fab-button
      v-if="!readonly && hasEditPerm($route.meta.permission.idobjeto) && items.length > 0"
      :icon="$vuetify.icons.values.edit"
      @click="clickEdit"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './DatoListData'
import DatoListItem from './components/DatoListItem'
import { get } from 'vuex-pathify'
import { getTablaById } from '@/utils/consts'

const pageStoreName = 'pagesDatoList'

export default {
  components: {
    DatoListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      pageStoreName,
      tablaPrincipal: {},
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    readonly: get(`${pageStoreName}/readonly`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.tablaPrincipal = getTablaById(parseInt(this.routeParams.idtabla))
      this.title = `Datos personalizados de ${this.tablaPrincipal.titleSingular}`
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectDato(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idtabla,
          this.routeParams.id,
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectDatoRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'iddato')
      } finally {
        this.loadingData = false
      }
    },
    clickEdit () {
      // sin remember porque actualiza múltiples registros
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idtabla: this.routeParams.idtabla,
          id: this.routeParams.id,
        },
      })
    },
  },
}
</script>
